<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import Pagination from '@src/components/base/pagination'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'
import axios from '@/axios'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Gym',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, Pagination, PageHeader },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      title: 'Gym',
      filecsv: null,
      showOverlay: false,
      search: {},
      page: 1,
      data: [],
      pagination: {},
      sort_by: '',
      sort_type: '',
      searchData : ''
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    sumbitSearch() {
      this.onLoad()
    },
    onPage(page) {
      this.page = page
      this.onLoad()
    },
    async onLoad() {
      await this.axios
        .get('/api/gym', {
          params: {
            page: this.page,
            size: 10,
            search : this.searchData,
          },
        })
        .then((res) => {
          this.data = res.data.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.error.message)
        })
    },

    async handleClickDeleteContent(id) {
      var confirmDel = confirm('Are you sure you want to delete?')
      if (confirmDel) {
        await this.axios
          .delete(`/api/gym/${id}`)
          .then((result) => {
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },

    async handleClickEditContent(data) {
      console.log(data)
      this.$router.push({
        path: '/gym/edit',
        query: { id: data._id },
      })
    },
    async uploadCsv() {

      const data = new FormData()
      data.append('csv', this.filecsv)
      const request = axios.post('/api/gym/uploadcsv', data)
      this.showOverlay = true
      await request.then(async (result) => {
        if (result.status === 200) {
          setTimeout(() => { this.$toast.success('Saved successfully'); }, 3000);
          setTimeout(() => {
            this.showOverlay = false
            this.$router.go()
          }, 3000);

        } else {
          this.showOverlay = false
          this.$router.go()
        }
      })
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
          this.showOverlay = false
          this.$router.go()
        })

    }


  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
  <div class="col-md-3 col-xl-4">
    <h4 class="mb-1 mt-0">Gym</h4>
  </div>

  <div class="col-xl-8">
    <div class="row align-items-center">
      <div class="col-md-8">
        <router-link to="/gym/create">
          <button class="btn btn-primary rounded-pill width-lg float-right aligncenter">
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
        <button class="btn btn-success rounded-pill width-lg float-right aligncenter mr-2" v-b-modal.modal-1>
          <feather type="file-plus"></feather>Upload CSV
        </button>
      </div>
      <div class="col-md-4">
        <b-input-group>
          <b-form-input v-model="searchData"></b-form-input>
          <b-input-group-append>
            <b-button  @click="sumbitSearch" variant="dark">search</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
  </div>
</div>


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(gym, index) in data" :key="index">
                    <td scope="row">{{ index + 1 }}</td>
                    <td v-if="gym.image[0]" scope="row" style="width: 340px; height: 195px;">
                      <img :src="`${baseImageUrl}/${gym.image[0].url}`" alt class="img-fluid" style="" />
                    </td>
                    <td v-else></td>

                    <td scope="row" style="width: 35%; height: 10%;">
                      {{ gym.title }}
                    </td>


                    <td class="d-flex">
                      <a class="btn btn-warning p-0 px-2 py-1 mr-2" :href="'/gym/edit/' + gym._id">Edit</a>
                      <b-button @click="handleClickDeleteContent(gym._id)" class="btn btn-danger p-0 px-2 py-1"
                        v-b-modal.modal-center>Delete</b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination :data="pagination" :page="page" @onPage="onPage($event)" />
          </div>
        </div>
      </div>
      <!-- modal -->
      <b-modal id="modal-1" centered title="Upload  Gym" hide-footer>
        <b-overlay :show="showOverlay">
          <b-card :aria-hidden="showOverlay ? 'true' : null">

            <b-form-file v-model="filecsv" :state="Boolean(file1)" placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."></b-form-file>
            <!--  <div class="mt-3">Selected file: {{ filecsv ? filecsv.name : '' }}</div> -->
            <b-button class="float-right mt-4" :disabled="filecsv === null ? true : false" variant="primary"
              @click="uploadCsv">UPLOAD</b-button>
          </b-card>
        </b-overlay>
      </b-modal>
    </div>
  </Layout>
</template>

<style lang="scss">
.sizeImage {
  width: 50%;
  height: 50%;
}
</style>
